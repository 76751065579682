import React from "react";
import Layout from "../Components/Layout";
import { machinery } from "../Data/allData";

const Machinery = () => {
  return (
    <Layout>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center mt-5">
            <h2 style={{ fontFamily: "'Times New Roman', Times, serif" }}>
              Automatic Production Line Machinery
            </h2>
            <hr className="mx-auto mt-2 " style={{ width: "400px" }} />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          {machinery.map(item => (
            <div key={item.id} className="card m-4 text-center mx-auto" style={{ width: "18rem" }}>
              <img src={item.image} className="card-img-top" alt={item.title} />
              <div className="card-body">
                <p className="card-text" style={{color:"#9a050C"}}>{item.title}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Machinery;
