import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import './slider.css';



const Slider1 = ({data}) => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="slider-container container">
      <Slider {...settings}>
      
      {data.map(title => (
          <div key={title.id} className="skincare-container mt-5">
            <div className="circle-background">
              <div className="circle-outline">
                <img src={title.image} alt=""
                  className="product-image"
                />
              </div>
            </div>
            {/* <h2 className="heading">Skincare</h2> */}
          </div>
        ))}
 
      
      </Slider>
    </div>
  );
};

export default Slider1;
