import React, { useState } from 'react'
import Layout from '../Components/Layout'
import Categories from '../Components/Productcontent/Categories'
import Menu from '../Components/Productcontent/Menu'
import items from '../Data/data'



const allCategories = ['all', ...new Set(items.map((item) => item.category))]

const Product = () => {
    const [menuItems, setMenuItems] = useState(items)

    // const [categories, setCategories] = useState(allCategories)
    const categories = allCategories
  
    const filterItems = (category) => { 
      if (category === 'all') {
        setMenuItems(items)
        return
      }
      const newItems = items.filter((item) => item.category === category)
      setMenuItems(newItems)
    }
    // setCategories(categories)

    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 16;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records = menuItems.slice(firstIndex, lastIndex);
    
    const npage = Math.ceil(menuItems.length / recordsPerPage);  
    const numbers = [...Array(npage + 1).keys()].slice(1); 
    const prePage = () => {
      if (currentPage !== 1) setCurrentPage(currentPage - 1);
    };
  
    const changeCPage = (n) => {
      setCurrentPage(n);
    };
  
    const nextPage = () => {
      if (currentPage !== npage) setCurrentPage(currentPage + 1);
    };
   
  return (
    <Layout>
         <main>
      <section className="menu section">
        <div className="title ">
          <h2 className='product mt-5'>Our Products</h2>
          <div className="underline"></div>
        </div>
        <div className='container'>
          <div className='row '>
            <div className='col-md-12'>
            <Categories categories={categories} filterItems={filterItems} />
            </div>
          </div>

        </div>
        <div className='container mt-5'>
          <div className='row'>
            <div className='col-md-12'>
        <Menu items={records} />

            </div>
          </div>

        </div>
        
      </section>
    </main>

    {/* //pagination */}
    <nav className='container justify-content-center text-center mx-auto bg-light'>
  <ul className='pagination'>
    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
      <button className='page-link text-dark' onClick={prePage} aria-label="Previous Page">Prev</button>
    </li>
    {numbers.map((n, i) => (
      <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
        <button className='page-link text-dark' onClick={() => changeCPage(n)} aria-label={`Page ${n}`}>{n}</button>
      </li>
    ))}
    <li className={`page-item ${currentPage === npage ? 'disabled' : ''}`}>
      <button className='page-link text-dark' onClick={nextPage} aria-label="Next Page">Next</button>
    </li>
  </ul>
</nav>

      
    </Layout>
  )
}

export default Product
