import React from 'react'

const Des = () => {
  return (
    <div className='container'>
      <div className='row'>
      <div className="col-md-12 text-center mt-5">
            <h1 style={{ fontFamily: "'Times New Roman', Times, serif" }}>
              What makes Us different
            </h1>
            <hr className="mx-auto mt-3 " />
          </div>
        <div>
        <section class="text-gray-600 body-font">
  <div class="container px-5 py-24 mx-auto">
    <div class="flex flex-wrap -m-4">
      <div class="p-4 lg:w-1/3">
        <div class="h-full bg-gray-100 bg-opacity-75 px-8 pt-10 pb-10 rounded-lg overflow-hidden text-center relative">
          <h2 class="tracking-widest  text-gray-400 mb-1"><i class="fa-solid fa-transgender"></i></h2>
          <h1 class="title-font sm:text-2xl text-xl font-medium text-gray-900 mb-3" style={{color:"#9a050C"}}>Wide Range Of Products</h1>
          <p class="leading-relaxed mb-3">We offer a wide range of products to meet all your needs, from paper and cosmetics to garments, stationery, and disposables.</p>
        </div>
      </div>
      <div class="p-4 lg:w-1/3">
        <div class="h-full bg-gray-100 bg-opacity-75 px-8 pt-10 pb-10 rounded-lg overflow-hidden text-center relative">
          <h2 class="tracking-widest  text-gray-400 mb-1"><i class="fa-regular fa-user"></i></h2>
          <h1 class="title-font sm:text-2xl text-xl font-medium text-gray-900 mb-3" style={{color:"#9a050C"}}>User-Friendly Interface</h1>
          <p class="leading-relaxed mb-3">Our website features a user-friendly interface, making it easy to browse and find the products you need quickly and efficiently.</p>
        </div>
      </div>
      <div class="p-4 lg:w-1/3">
        <div class="h-full bg-gray-100 bg-opacity-75 px-8 pt-10 pb-10 rounded-lg overflow-hidden text-center relative">
          <h2 class="tracking-widest  text-gray-400 mb-1"><i class="fa-regular fa-handshake"></i></h2>
          <h1 class="title-font sm:text-2xl text-xl font-medium text-gray-900 mb-3" style={{color:"#9a050C"}}>Commitment to Excellence</h1>
          <p class="leading-relaxed mb-3">We are dedicated to a commitment to excellence, ensuring that every product we offer meets the highest standards of quality and performance.</p>
        
        </div>
      </div>
    </div>
  </div>
</section>
        </div>

      </div>
    </div>
  )
}

export default Des
