import React from "react";
import "./header.css";
import { Link, useLocation } from "react-router-dom";
import { navData } from "../../Data/allData";

const Header = () => {
    const location = useLocation();
    const sidebarMenu = navData
  return (
    <div>
      <header>
      <div class="top-bar">
      <div class="top-bar__content">
        <section class="phone">
          <i class="fa-solid fa-phone icon"></i>
          <span>+0086 134560012967</span>
        </section>
        <section class="email">
          <i class="fa-solid fa-envelope icon"></i>
          <span>sales.baozon@baozonghaolimited.com</span>
        </section>
      </div>
    </div>
    <div className="header-bar">
        <nav>
            <div className="logo">
        <Link to="/" >
          <img class="logo__img" src="./assets/logo3.png" alt="logo"/>
        </Link>
        <Link to="/" > 
        <span class="logo__text" >BAO ZONG HAO LIMITED</span>
        </Link>
            </div>
      
       
        <input type="checkbox" id="click"/>
        <label for="click" className="menu-btn" >
          <i class="fas fa-bars"></i>
        </label>
       
        <ul>

        { sidebarMenu.map(menu =>{
    const isActive = location.pathname === menu.path
    return(
        <div className= {`${isActive && 'active'}`}>
          <li>

            {/* <i className={menu.icon}></i> */}
            <a href={menu.path}>{menu.name}</a>
          </li>
            </div>
    )
})}
          
        </ul>
        </nav>

    </div>
      </header>
    </div>
  );
};

export default Header;
