import React from "react";
import Slider from "react-slick";
import "../Slider/slider.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const Slider2 = ({ data, title , para}) => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 762,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="bg-left-half pb-5">
      <h2 className="p-3"  style={{fontFamily:"cursive" , color:"#9a050C"}}> {title} </h2>
      <div className="container">

      <p >{para}</p>
      </div>
      <div className="slider-container container ">
        <Slider {...settings}>
          {data.map(item => (
            <div key={item.id} className="skincare-container mt-5">
              <div className="">
                <div className="card" id="card" style={{ width: "18rem" }}>
                  <img src={item.image} className="card-img" id="card-img" alt="card-img" />
                  </div>
              </div>
            </div>
          ))}
         
        </Slider>
      </div>
    </div>
  );
};

export default Slider2;
