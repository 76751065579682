import React from 'react'
import { Link } from 'react-router-dom'
import './footer.css'

const Footer = () => {
  return (
    <div>
  

      {/* <hr className='mt-5'/> */}
        <footer class="site-footer ">
    <div class="container ">
      <div class="footer-content ">
        <p class="copyright">&copy; 2024 Baozonghaolimited. All rights reserved.</p>
        <ul class="footer-links">
          <li><Link to="/about">About</Link></li>
          <li><Link to="/job">Career</Link></li>
          <li><Link to="/product">Products</Link></li>
          <li><Link to="/contact">Contact us</Link></li>
          <li><Link target='blank' to="https://www.facebook.com/baozonghaolimited"><i class="fa-brands fa-facebook"></i></Link></li>
          <li><Link target='blank' to="https://www.instagram.com/baozonghaolimited"><i class="fa-brands fa-square-instagram"></i></Link></li>
          <li><Link target='blank' to="https://www.linkedin.com/company/baozonghaolimited"><i class="fa-brands fa-linkedin"></i></Link></li>
          <li><Link target='blank' to="https://www.youtube.com/@baozonghaolimited"><i class="fa-brands fa-youtube"></i></Link></li>
          
        </ul>
      </div>
    </div>
  </footer>

 
    </div>
  )
}

export default Footer
